header
  position: fixed
  top: 0
  left: 0
  right: 0
  display: flex
  justify-content: center
  align-items: center
  height: 80px
  background-color: $white
  box-shadow: 0 3px 12px rgba(0,0,0,.05)
  z-index: 2000
  transition: all ease-out .5s
  &.hidden
    transform: translateY(-100%)
  &.visible
    transform: translateY(0)
  nav
    width: 100% !important
    max-width: 100% !important
    display: flex
    justify-content: space-between
    align-items: center
    .menu
      +v-sm
        position: fixed
        top: 40px
        left: 0
        right: 0
        background-color: $white !important
      a
        color: $dark !important
        font-weight: 400
        font-size: 1em
    #logo
      margin-top: 3px
      +v-sm
        display: flex !important
        justify-content: space-between
        width: 63%
      +v-xs
        width: 80%
      img
        +v-xs
          zoom: .8
      .p-4
        +v-sm
          transform: translateY(-5px)
    .menu
      position: fixed
      display: block
      top: 80px
      left: 0
      right: 0
      width: 100% !important
      height: calc(100vh - 80px)
      background-color: $white
      z-index: 999
      &.active
        display: none
      a
        display: flex
        justify-content: center
        align-items: center
        height: 60px
    .button
      +v-sm
        transform: translateX(-60px)
      +v-xs
        text-align: center !important
        font-size: .65em !important
section
  +v-xs
    width: 100% !important
.container
  +v-xs
    width: 90% !important
    min-width: 90% !important
    max-width: 90% !important
    margin-left: auto !important
    margin-right: auto !important

#top
  .menu
    a
      &:hover
        color: $theme !important

.burger
  position: absolute
  top: 32px
  right: 340px
  display: block
  width: 24px
  height: 18px
  cursor: pointer
  +v-sm
    right: 30px
  span
    width: 24px
    height: 2px
    background-color: $dark
    transition: all ease-out .25s
    &.tt
      position: absolute
      left: 0
      top: 0
    &.mm
      position: absolute
      left: 0
      top: 8px
    &.bb
      position: absolute
      left: 0
      top: 16px
  &.open
    span
      width: 24px
      height: 2px
      background-color: $dark
      &.tt
        position: absolute
        left: 0
        top: 8px
        transform: rotate(45deg)
      &.mm
        width: 0
        position: absolute
        left: 12px
        top: 16px
        background-color: transparent
      &.bb
        position: absolute
        left: 0
        top: 8px
        transform: rotate(-45deg)


.mobile-hidden
  +v-sm
    display: none !important

.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 40px
  padding: 0 24px
  color: $white !important
  font-size: 1em
  font-weight: 400
  border-radius: 4px
  transition: all ease-out .25s
  background-color: $theme
  outline: none !important
  border: none !important
  &:hover
    color: $white !important
    background-color: $dark !important
  &.dark
    color: $white !important
    background-color: $dark
    height: 48px
    padding: 0 32px
    &:hover
      color: $white !important
      background-color: $button !important
  &.outline
    color: $dark !important
    border: 2px solid $dark !important
    background-color: transparent !important
    &:hover
      color: $white !important
      border: 2px solid $theme !important
      background-color: $theme !important

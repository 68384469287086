html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  padding-top: 80px
  color: $light
  font-size: 95%
  font-family: $basic
  font-weight: 300
  letter-spacing: .03em
  line-height: 1.6
  -webkit-font-smoothing: subpixels-antialised
  -moz-osx-font-smoothing: grayscale
  font-smooth: always
  overflow-x: hidden
  background-color: $body
  &.menu-active
    height: 100vh
    overflow: hidden

.mob-button
  +v-sm
    position: fixed
    right: 80px
    top: 16px

a, * a
  color: inherit !important
  text-decoration: none !important

.col-lg-*
  +v-sm
    margin-top: 10px
    margin-bottom: 10px


#results,
#about,
#donations
  *
    +v-sm
      text-align: center !important

#top
  +v-sm
    *
      text-align: center !important
  a
    &.c-theme
      +v-xs
        width: 80%
        font-size: .75em !important
        line-height: 1 !important
        text-align: left !important
  .p-5
    +v-xs
      padding: 24px !important
  img
    display: block
    width: 100%
    height: auto
    object-fit: cover
    border-radius: 40px
    +v-xs
      width: 80% !important
    +v-xs
      width: 90% !important
      object-fit: cover


.meta
  span
    display: inline-flex
    justify-content: center
    align-items: center
    margin: 4px
    padding: 2px 8px
    color: $dark
    background-color: $light
    border-radius: 4px

.val
  display: flex
  justify-content: center
  align-items: center
  width: 80px
  height: 40px
  padding-top: 4px
  padding-bottom: 0
  border: 2px solid $dark
  border-radius: 24px

footer
  +v-sm
    display: block
    padding: 40px 20px !important
    height: auto
    min-height: auto !important
    text-align: center !important
  img
    transition: all ease-out .3s
    &:hover
      opacity: .5

img
  +v-sm
    width: auto !important
    max-width: 100% !important
    margin-left: auto !important
    margin-right: auto !important

.m-30
  display: none
  +v-sm
    display: block
    width: 100%
    height: 30px
.m-20
  display: none
  +v-sm
    display: block
    width: 100%
    height: 20px

.bg-pic
  &-1
    background: url(../../assets/png/1.png) left bottom no-repeat
    border-bottom: none !important
    background-size: 36%
    +v-md
      background-size: 24%
    +v-sm
      background: none !important
  &-2
    background: rgba(255,255,255,.75) url(../../assets/png/2.png) right bottom no-repeat
    background-size: 64%
    border-bottom: none !important
    +v-md
      height: 65vh
    +v-sm
      background-size: 55%
      margin-bottom: 40px !important


#clients
  position: relative
  display: block
  width: 100%
  height: 100%
  .map
    position: absolute
    top: -24px
    right: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2
    +v-sm
      max-width: 80% !important
      top: -60px
      left: auto
      right: auto
    +v-xs
      max-width: 86% !important
      top: -40px
      left: auto
      right: 10%
    img
      position: absolute
      top: 80px
      right: 50px
      +v-sm
        zoom: .6 !important
        position: absolute
        top: 400px
        right: -20px

.greyscale
  position: relative
  display: block
  opacity: .5
  filter: grayscale(100%)
  transition: all ease-out .3s
  z-index: 1000
  &:hover
    opacity: 1
    filter: grayscale(0)
    cursor: pointer

#top
  margin-top: -80px
  +v-sm
    margin-top: -120px
  +v-xs
    margin-top: -60px
  img
    width: 80px
    height: 80px
    object-fit: contain

.mobile-block
  *
    +v-sm
      font-size: .9em !important
  a
    +v-sm
      margin-left: 20px
  +v-sm
    display: flex !important
    width: 100% !important
  img
    +v-sm
      width: 40px !important
      height: 40px !important

.form
  +v-sm
    padding: 40px 0
  .s-40
    +v-sm
      display: none !important

.title-intro
  &.upp
    +v-sm
      text-align: center !important

#about
  +v-sm
    margin-top: -180px
  +v-xs
    margin-top: -150px
  .form
    .title
      +v-md
        text-align: center !important
  .button
    +v-md
      height: 80px
      text-align: center !important
    +v-md
      height: 50px

#recruiting
  .title
    +v-md
      font-size: 1.4em !important

#capacity
  .title, p
    +v-sm
      margin-left: auto !important
      margin-right: auto !important
      text-align: center !important

#clients
  +v-xs
    .row
      margin-top: -120px !important
      .p-5
        padding: 24px 16px !important
        p
          font-size: .6em !important
  .col-lg-3
    +v-md
      width: 50% !important
      max-width: 50% !important
      p
        font-size: .8em !important

#contact
  +v-xs
    margin-top: -80px !important


.s-40,
.s-30,
.s-20
  +v-xs
    height: 10px !important

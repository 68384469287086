.title
  margin: 0
  padding: 0
  color: $light
  font-size: 1.7em
  font-weight: 600
  +v-sm
    margin: 0 0 16px 8px
  +v-xs
    font-size: 1.5em
  &-mini
    font-size: 1.1em
  &-cta
    font-size: 2.25em
    font-weight: 400
    line-height: 1.6
    +v-sm
      font-size: 1.65em
      font-weight: 400
      line-height: 1.4
    +v-xs
      font-size: 1.15em
      line-height: 1.25
  &-intro
    font-size: 4.5em !important
    font-weight: 600
    +v-md
      font-size: 3.5em !important
    +v-sm
      font-size: 2.5em !important
    +v-xs
      font-size: 1.7em !important
    &-sub
      font-size: 1.25em
      font-weight: 400
      line-height: 1.6
    &-cta
      font-size: 2em
      font-weight: 700
  &-sub
    font-size: 1.15em
    font-weight: 300
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
